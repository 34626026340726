/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    // Function to update the iframe source based on window width
    const updateIframeSrc = () => {
      const src = window.innerWidth < 1300
        ? 'https://charts.mongodb.com/charts-project-0-otkld/embed/dashboards?id=3e6656a5-78c0-4bf9-95cb-f484fbc7ebf4&theme=dark&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=scale'
        : 'https://charts.mongodb.com/charts-project-0-otkld/embed/dashboards?id=3e6656a5-78c0-4bf9-95cb-f484fbc7ebf4&theme=dark&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale';
      setIframeSrc(src);
    };

    // Call once and set up the event listener on window resize
    updateIframeSrc();
    window.addEventListener('resize', updateIframeSrc);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateIframeSrc);
    };
  }, []);
  return (
    <div className="App">
      <div className="toolbar">
        <img src={logo} className="logo" alt="logo" />
        {/* <div className="stats">
          Anybodies analytics
        </div> */}
      </div>
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p className="title">ANYBODIES STATS</p>
      </header>
      <iframe
        style={{
          border: "none",
          borderRadius: "2px",
          boxShadow: "none",
          width: "100vw",
          maxWidth: "1300px",
          height: "2500px",
        }}
        src={iframeSrc}
      ></iframe>
    </div>
  );
}

export default App;
